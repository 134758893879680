.persona{
    background-color: $color-white;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-content: center;
    border: 1px solid $gray2;
    &:hover{
        border-color: $azul;
    }
}

.escudo{
    background-color: $color-white;
    @include border;
    padding: .3rem;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: flex;
    align-content: center;
    @include mobile{
        width: 50px;
        height: 50px;
        overflow: hidden;
        max-width: 50px;
    }
}

.logo{
    max-width:190px;
    margin: 1rem auto;
}

.logo-nav{
    width: 100px;
    margin-left: 1rem;
    @include mobile{
        display: none;
    }
}

.logo-footer{
    max-width: 120px;
    margin: 0 auto;
}

.icon-loading{
    width: 60px;
    height: 60px;
    @include tablet{
        width: 100px;
        height: 100px;
    }
}

.user{
    background-color: $color-white;
    border:1px solid $azul;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    display: flex;
    align-content: center;
}

.escudo-tabla{
width: 45px;
height: 45px;
}