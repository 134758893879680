.modal-background{
    background-color: rgba($navy,.9);
}

.modal-card-title{
    font-family: $font-title;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
}

.delete, .modal-close{
 
    color: $color-dark;
    
}

.modal-card-head, .modal-card-foot{
    background-color: $color-white;
}

footer.footer-card{
    background-color: $color-white;
    padding: 1rem;
    border-top: 1px solid $gray2;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.modal-card{
    @include mobile{
        width: 95%;
    }
    &.is-dark{
        .modal-card-head{
            background-color: $navy-light2;
            .modal-card-title{
                color: $white;
            }
            .button.is-circle{
                background-color: transparent;
                color: $white;
            }
        }
    }
}