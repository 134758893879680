p.lead.markdown {
  ol, ul {
    margin: 10px 0 10px 25px;
  }
  ul {
    li {
      list-style: disc;
    }
  }
  p {
    margin-bottom: 2em;
  }
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 1;
  z-index: 1;
  transition: opacity .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  .loader {
    height: 80px;
    width: 80px;
  }
}

.pointer {
  cursor: pointer;
}

.is-rounded-disabled {
  border-radius: 0;
  font-size: 14px;
}

.is-maxw-90 {
  max-width: 90px;
}