.table{
    td,th{
    color:$color-base;
    text-align: left;
    @include mobile{
        text-align: left;
    }
    &.hl{
        background-color: rgba($navy-medium, .05);
        background-blend-mode: multiply;
    }
    }
    
    thead th{
        border-width: 0 0 1px;
        color:$gray;
        // font-weight: 400;
    }
    tr.tr-total{
        
        td{
            font-weight: 600;
            background-color: $azul-light;
            
        }
        
    }
    &.has-col-sticky, &.is-vcentered{
        td,th{
            vertical-align: middle;
        }
    }
}

.table.is-striped tbody tr:not(.is-selected):nth-child(even){
    background-color: $navy-light;
}

.table-container{
    table.table{
        th,td{
            white-space: nowrap;
        }
    }
}

//sticky column solution 
.table-container-sticky {
	position: relative;
	overflow: auto;
  	white-space: nowrap;
    padding-bottom: 2rem;
}

.sticky-col {
    position: sticky;
    position: -webkit-sticky;    
    background-color: white;
    white-space: break-spaces !important;
    z-index: 1;
    // @include tablet{
    //     white-space: none;
    // }
}
  
.first-col {
	width: 110px;
    min-width: 110px;
    max-width: 110px;
	left: -1px;
    @include tablet{
        width: 140px;
    min-width: 140px;
    max-width: 140px;
    }
}

.first-col-wide {
	width: 118px;
    min-width: 118px;
    max-width: 118px;
	left: -1px;
    @include tablet{
        width: 140px;
    min-width: 140px;
    max-width: 140px;
    }
}

//table goes block on mobile

.table-block-xs{
    @include mobile{
        width: 100%;
        display: inline-table;
        th{
            display: none;
        }
        td{
            display: block;
            width: 100%;
        }
    }
}

.table-block{
    width: 100%;
        display: inline-table;
        th{
            display: none;
        }
        td{
            display: block;
            width: 100%;
        }
}