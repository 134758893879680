.button{
    border-radius: 120px;
    transition: background-color 0.5s ease;
    font-family: $font-base;
    font-size: $font-size-base;
    font-weight: 400;
    padding-bottom: calc(0.4em - 1px);
    &.is-primary{
        background-color:$primary;
        border-color: $primary;
        &:hover{
            background-color: $navy;
            border-color: $navy;
        }
    }
    &.is-white{
        background-color: $color-white;
        &:hover{
            background-color: $azul;
        }
    }
    &.is-transparent{
        background-color: transparent;
        border-color: transparent;
    }
    &.is-login{
        padding: 1.5rem 2rem;
    }
    &.is-line-white{
        background: transparent;
        border-color: $color-white;
        color: $color-white;
        &:hover{
            border-color: $primary;
            color: $primary;
        }
    }
    &.is-secondary{
        background: transparent;
        border-color: $primary;
        color: $primary;
        &:hover{
            border-color: $navy;
            color: $navy;
        }
    }
    &.is-circle{
        padding:0;
        width: 45px;
        height: 45px;
    }
    &.is-circle-sm{
        padding:0;
        width: 35px;
        height: 35px;
    }
    &.is-user{
        border:2px solid $white;
        padding: .2rem;
        height: auto;
        &:hover, &:focus{
          opacity: .8;
        }
    }
    &.is-nav-toggle{
        // width: 40px;
        // height: 40px;
        // min-width: 40px;
        .material-icons{
            font-size: 24px;
        }
    }
}

.button-persona{
    transition: color 0.5s ease;
    &:hover{
        cursor: pointer;
        color: $azul;
        img.persona{
            border: 1px solid $azul;
        }
    }
    p{
        margin-right: .5rem;
        @include mobile{
            display: none;
        }
    }
    img.persona{
        border: 1px solid $gray;
    }
}

.buttons-footer{
    // border-top:1px solid $gray-light;
    // padding: 1rem;
    @include mobile{
        .buttons{
            flex-direction: column-reverse;
            .button{
                margin:0 0 .5rem 0 !important;
                width: 100%;
            }
        }
    }
    @include tablet{
        .buttons{
            justify-content: flex-end;
        }
    }
}

.btn-block-xs{
    @include mobile{
        display: block;
        width: 100%;
        margin:0 0 .5rem 0 !important;
    }
}
