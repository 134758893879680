//Google Fonts & icons
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

//Variables

// Brand colors
$azul: #00B2E3;
$azul-light: #CCEDF7;
$navy:#20293B;
$navy-light:#f8fbfd;
$navy-light2:#2a779e;
$navy-medium:#094863;
$navy-dark:#131a2f;
$gray:#999999;
$gray0:#f0f0f0;
$gray1:#E5E1E6;
$gray2:#D0CFCD;
$color-base:#333333;
$color-dark:#222222;
$dark:#222222;
$color-light:#666666;
$color-white:#ffffff;
$success:#88EAA2;
$danger:#FD5E60;

// Update Bulma's global variables
$primary: $azul;
$link:$azul;
$box-color:$color-base;
$tabs-link-active-color:$azul;
$tabs-link-active-border-bottom-color:$azul;
$input-shadow: none;
$dark:$color-dark;

$tabs-toggle-link-active-background-color:$azul;
$tabs-toggle-link-active-border-color:$azul;
$tabs-toggle-link-border-color:$navy-light2;
$tabs-link-color:$azul;
$tabs-link-hover-color:$azul;
// $tabs-toggle-link-radius:200px;
$tabs-toggle-link-hover-border-color:$color-white;
$tabs-toggle-link-border-color:$azul;

// static content
 $static-content: 'https://bulldog-public.s3.eu-west-3.amazonaws.com';
// $static-content: '../public';

//Mixins
@mixin border{
    border:1px solid $gray1;
}

@mixin border-radius{
    border-radius: 6px;
}

@mixin box-shadow{
    box-shadow: 0px 0px 12px 4px rgba(0,178,227,0.3);
}


//Bulma stuff
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/elements/_all.sass";
@import "../../node_modules/bulma/sass/grid/_all.sass";
@import "../../node_modules/bulma/sass/form/_all.sass";
@import "../../node_modules/bulma/sass/helpers/_all.sass";
@import "../../node_modules/bulma/sass/form/_all.sass";
@import "../../node_modules/bulma/sass/layout/_all.sass";
@import "../../node_modules/bulma/sass/components/_all.sass";

//Custom

@import "layout";
@import "navigation";
@import "spacing";
@import "typography";
@import "images";
@import "buttons";
@import "boxes";
@import "tables";
@import "modal";
@import "bulma-checkradio";
@import "backgrounds";
@import "form";
@import "bulma-timeline";
@import "bulma-popover";
@import "bulma-badge";
@import "dev";
