

.nav-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
    padding: .5rem 1rem;
    @include tablet{
        padding: .5rem 2rem;
    }
    // border-bottom: 1px solid rgba($primary,.5);
    position: fixed;
    top: 0;
    left: 0;
    background-color: $white;
    // background: linear-gradient(333deg, $navy 42%, #1C516C 99%);
    width: 100%;
    z-index: 31;
    border-bottom: 1px solid $gray0;
    &.nav-secondary{
        background-color: $navy-light2;
        border-bottom: $navy;
    }
    &.nav-three{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: $navy;
        border-bottom: $navy;
        + .navbar{
            position: fixed;
            top: 60px;
            left: 0;
            width: 100%;
            .navbar-menu{
                @include mobile{
                    height: 100vh;
                    position: fixed;
                    width: 100%;
                    overflow-y: scroll;
                }
            }
            + main.main.pt-1{
                padding-top: 8.5rem !important;
                
            }
        }
        .nav-main-item:first-of-type{
            text-align: center;
            width: 100%;
            margin-left: 45px;
        }
        
        .nav-main-item h1{
            color: $white;
        }

        .button.is-circle{
            background-color: transparent;
            color: $white;
        }

    }
    .nav-main-item{
        h1{
            @include mobile{
                font-size: 14px;
            }
        }
        &.nav-left{
           @include tablet{
               width: 20%;
           }
        }
        &.nav-center{
            @include tablet{
                width: 60%;
            }
        }
        &.nav-right{
            @include tablet{
                width: 20%;
            }
           
        }
    }
}

.navbar-gray{
    background-color: $gray0;
    .navbar-item{
       
        &:hover, &.is-active{
            background-color: transparent;
            color: $dark;
        }
    }
    .navbar-menu{
        @include mobile{
        background-color: $navy;
        background-image: url(#{$static-content}/images/bg/bgph13.jpg);
        background-attachment: fixed;   
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: cover;
        .navbar-item, .navbar-link{
            color: $white;
            &:hover{
                color: $primary;
            }
        }
        }
    }
    
}

.navbar-start.is-centered{
    justify-content: center;
    margin-right: inherit;
    align-items: center;
    align-content: center;
    width: 100%;
}

.tabs ul{
    border-bottom-color: transparent;
    li{
        a{
            border-color: $navy-light2;
            color: $navy-light2;
            &:hover{
                color: $azul;
                border-color: $azul;
            }
            @include tablet{
                font-size: 20px;
            }
        }
    }
    li.is-active a{
        color: $color-white;
        border-color: $color-white;
    }
}

.tabs.is-toggle a{
    color: $navy-light2;
    @include tablet{
        font-size: 18px;
    }
}

//overlay fullwidth navigation

.overlay-nav {
    height: auto;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 2;
    top: 57px;
    left: 0;
    background-color: $navy;
    //añadimos imagen bg
    background-image: url(#{$static-content}/images/bg/bgph13.jpg);
    background-attachment: fixed;   
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    //
    padding: 2rem;
    overflow: hidden;
    overflow-y: auto;
    @include mobile{
        height: 100vh;
        padding: 2rem 2rem 5rem 2rem;
    }
    &.is-secondary{
        background-color: $navy-light2;
        background-image: url(#{$static-content}/images/bg/bg6.jpg);
    }
    .overlay-content{
        display: block;
        width: 100%;
    }
    //   .btn-close {
    //     position: absolute;
    //     top: 1rem;
    //     right: 1rem;
    //     font-size: 20px;
    //     color: $color-white;
    //   }

      .nav-item{
          display: block;
          padding: .5rem 0;
          color: $color-white;
          margin: .5rem;
        //   background-color: rgba($primary, 0.03);
        //   border-radius: 120px;
          &:hover, &.is-active{
            //   background-color: rgba($primary, .4);
              color: $azul;
          }
          &.is-locked{
              color: $danger;
          }
      }
  }