$family-sans-serif: 'Titillium Web', sans-serif;
$font-base: $family-sans-serif;
$font-title:"Montserrat", sans-serif;

$font-size-base:17px;
$font-size-title1:16px;
$font-size-title2:16px;

::-moz-selection { /* Code for Firefox */
    color: $color-white;
    background: $azul;
  }
  
  ::selection {
    color: $color-white;
    background: $azul;
  }

body{
    font-family: $font-base;
    color: $color-base;
    font-weight: 400;
}

body, .menu, .tabs{
    @include desktop{
        font-size: $font-size-base;
    }
}

a{
    color: $azul;
    &.is-neg{
        &:hover{
            color: $white;
        }
    }
}

// i{
//     position: relative;
//     top:2px;
//     &.icon-large{
//         font-size: 30px;
//     }
//     &.icon-medium{
//         font-size: 22px;
//     }
//   }

  .material-icons{
      vertical-align: middle;
      // margin-top: -2px;
      font-size: 18px;
  }
  

  h1, h2, h3{
    font-family: $font-title;
    font-weight: 500;
    color: $dark;
    text-transform: uppercase;
  }

  h1{
      font-size: $font-size-title1;
  }
  

  h2, h3{
    font-size: $font-size-title2;
  }

  h3.h3-small{
      font-size: 14px;
  }

  h3.h3-header{
    padding: 1rem;
    @include border-radius;
    background-color: $navy-light2;
    color: $white;
    
  }
  

  .text-regular{
      font-weight: 400;
  }

  .is-bold, .strong{
      font-weight: 600;
  }

  .is-title{
      font-family: $font-title;
      text-transform: uppercase;
      font-weight: 500;
  }

  .color-light{
      color: $color-white;
  }

  .color-dark{
    color: $color-dark;
}

  .color-gray{
      color: $gray;
  }

  .color-primary,.primary{
    color: $primary;
}

.logo-title{
    text-transform: uppercase;
    letter-spacing: .3rem;
    font-size: 13px;
    margin-top: .2rem;
    &.is-login{
        font-size: 14px;
        text-align: center;
        margin-top: 0;
    }
}

  .qty{
    //   font-family: $font-number;
      font-weight: 600;
      font-size: 33px;
      &.big{
          font-size: 43px;
      }
  }

  .qty-title{
      text-transform: uppercase;
      letter-spacing: .1rem;
      font-size: 16px;
  }

  .is-small{
      font-size: 14px;
  }
  

  //progress bar
  .progress.is-small{
    margin: .5rem 0;
    height: 6px;
}


//article

.article{
    color: $dark;
    p{
      margin-bottom: 1rem;
    }
    h2{
      margin-bottom: 1rem;
      font-weight: bold;
      padding: .5rem 1rem;
      background-color: $navy-light2;
      color: $white;
      border-radius: 3px;
      &:not(:first-of-type){
        margin-top: 2rem;
      }
    }
    h3{
        font-weight: bold;
        margin-bottom: 1rem;
        font-size: 14px;
    }
    ul{
        margin-bottom: 1rem;
        li{
          margin: 0 0 1rem 1rem;
          list-style-type: disc;
          ul{
              margin-top: .5rem;
              li{
                  list-style-type: circle;
                  ul{
                    margin-top: .5rem;
                    li{
                        list-style-type: square;
                    }
                }
              }
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
    }
  }