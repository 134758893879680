
$spaceamounts: ( 0, 1, 2, 3, 4, 5); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}rem !important;
      }
    
      .p#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space}rem !important;
      }
    }
  }

  //extras

  .mr-05{
    margin-right: .5rem;
  }

  .mb-05{
    margin-bottom: .5rem;
  }

  .mt-xs-2{
    @include mobile{
      margin-top: 2rem;
    }
  }

  