html,body{
    background-color: $navy-dark;
}

main.main{
    padding-top: 90px;
    padding-bottom: 2rem;
}

.container.is-fluid{
    @include mobile{
        padding-left: .8rem;
        padding-right: .8rem;
    }
}

aside.menu{
    width: 100%;
    height: 100vh;
    @include desktop{
        width: 100px;
        background: $azul;
    }
    @include mobile{
        display: none;
    }
}

.is-relative, .relative{
    position: relative;
}


.need-help{
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    @include mobile{
        // position: relative;
    }
}

//timeline

.timeline{
    color: $color-white;
    .timeline-content{
        .heading{
            font-size: 14px;
            color: $azul;
        }
    }
    .timeline-marker{
        height: 20px;
        width: 20px;
    }
    .timeline-item:before{
        width: 0.05em !important;
    }
}

.box-white{

    .body-box{
        .timeline{
            p{
                color: $navy-dark;
                &.heading{
                    color: $azul;
                }
            }
        }
    }
}


// login and loading
.login, .loading{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .content{
        padding: 2rem;
        @include tablet{
            min-width: 400px;
        }
    }
}

.d-flex{
    display: flex;
    align-items: center;
    .flex-end{
        margin-left: auto;
    }
    &.justify-center, &.is-centered{
        justify-content: center;
    }
    &.space-between{
        justify-content: space-between;
        align-items: center;
        @include mobile{
            display: block;
        }
    }
}

// user selector

.login-user{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
        background-size: cover;
   
}

.user-selector{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    @include tablet{
        flex-direction: row;
    }
    .user-item{
        padding: 1rem;
        flex-grow: 1;
        margin: .5rem;
        text-align: center;
        width: 250px;
        p{
            color: $white;
            margin-top: .5rem;
        }
        .is-user.is-sociedad{
            border-color: $azul;
            &:before{
                content: "S";
                background-color: $azul;
                width: 30px;
                height: 30px;
                color: $white;
                text-align: center;
                padding: 10px;
                position: absolute;
                top: 0;
                right: 8px;
                z-index: 2;
                border-radius: 100%;
                line-height: .6rem;
            }
           + p{
                color: $azul;
            }
        }
    }
}

footer{
    text-align: center;
    color: $white;
    padding: 2rem 0;
}