.bg-cover{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    &.is-100vh{
        height: 100vh;
    }
    &.is-fixed{
        background-attachment: fixed; 
    }
}

.bg-gradient{
    @extend .bg-cover;
    height: 100%;
    background-image: url(#{$static-content}/images/bg/bg-gradient.jpg);
}

.bg-texture{
    @extend .bg-cover;
    background-image: url(#{$static-content}/images/bg/bg4.jpg);
}

.bg-solid{
    background-color: rgba($primary, .04);
    height: 100vh;
}

.bg-1{
    @extend .bg-cover;
    background-image: url(#{$static-content}/images/bg/bg1.jpg);
}

.bg-3{
    @extend .bg-cover;
    background-image: url(#{$static-content}/images/bg/bg3.jpg);
}

.bg-4{
    @extend .bg-cover;
    height: 100%;
    background-blend-mode: multiply;
    background-image: url(#{$static-content}/images/bg/bg/stock-photo-goalkeeper-with-a-soccer-ball-in-the-stadium-712001851.jpg);
}

.bg-5{
    @extend .bg-cover;
    height: 100%;
    background-blend-mode: multiply;
    background-image: url(#{$static-content}/images/bg/bg/stock-photo-professional-football-or-soccer-player-in-action-on-stadium-with-flashlights-kicking-ball-for-1687861552.jpg);
}

.bg-6{
    @extend .bg-cover;
    background-image: url(#{$static-content}/images/bg/bg6.jpg);
}

.bg-dark{
    background-color: $navy;
}

.bg-white{
    background-color: $color-white;
}

.bg-main{
    background-attachment: fixed;   
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    background-image: url(#{$static-content}/images/bg/bg-main.jpg);
    background-color: $navy;
}


//Backgrounds with photos

.bg-login{
    background-image: url(#{$static-content}/images/bg/bg2.jpg);
    @include mobile{
        background-position: left bottom;
    }
}

.bg-user{
    background-image: url(#{$static-content}/images/bg/bgph13.jpg);
}

.bg1{
    background-image: url(#{$static-content}/images/bg/bgph1.jpg);
}
.bg2{
    background-image: url(#{$static-content}/images/bg/bgph2.jpg);
}
.bg3{
    background-image: url(#{$static-content}/images/bg/bgph3.jpg);
}
.bg4{
    background-image: url(#{$static-content}/images/bg/bgph4.jpg);
}
.bg5{
    background-image: url(#{$static-content}/images/bg/bgph5.jpg);
}
.bg6{
    background-image: url(#{$static-content}/images/bg/bgph6.jpg);
}
.bg7{
    background-image: url(#{$static-content}/images/bg/bgph7.jpg);
}
.bg8{
    background-image: url(#{$static-content}/images/bg/bgph8.jpg);
}
.bg9{
    background-image: url(#{$static-content}/images/bg/bgph9.jpg);
}
.bg10{
    background-image: url(#{$static-content}/images/bg/bgph10.jpg);
}
.bg11{
    background-image: url(#{$static-content}/images/bg/bgph11.jpg);
}
.bg12{
    background-image: url(#{$static-content}/images/bg/bgph12.jpg);
}
.bg13{
    background-image: url(#{$static-content}/images/bg/bgph13.jpg);
}
.bg14{
    background-image: url(#{$static-content}/images/bg/bgph14.jpg);
}
.bg15{
    background-image: url(#{$static-content}/images/bg/bgph15.jpg);
}
.bg16{
    background-image: url(#{$static-content}/images/bg/bgph16.jpg);
}
.bg17{
    background-image: url(#{$static-content}/images/bg/bgph17.jpg);
    background-attachment: fixed; 
}

.bg-card-help{
    background-image: url(#{$static-content}/images/bg/bg-modal-help.jpg);
    // background-image: url(../public/images/bg/bg-modal-help.jpg);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: cover;
}


// Backgrounds teams

.bg-osasuna{
    background: rgb(191,0,0);
background: -moz-linear-gradient(90deg, rgba(191,0,0,1) 0%, rgba(255,255,255,1) 50%, rgba(10,14,80,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(191,0,0,1) 0%, rgba(255,255,255,1) 50%, rgba(10,14,80,1) 100%);
background: linear-gradient(90deg, rgba(191,0,0,1) 0%, rgba(255,255,255,1) 50%, rgba(10,14,80,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bf0000",endColorstr="#0a0e50",GradientType=1);
}

.bg-alaves{
    background: rgb(11,55,159);
background: -moz-linear-gradient(90deg, rgba(11,55,159,1) 0%, rgba(255,255,255,1) 50%, rgba(11,55,159,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(11,55,159,1) 0%, rgba(255,255,255,1) 50%, rgba(11,55,159,1) 100%);
background: linear-gradient(90deg, rgba(11,55,159,1) 0%, rgba(255,255,255,1) 50%, rgba(11,55,159,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0b379f",endColorstr="#0b379f",GradientType=1);
}

.bg-albacete{
    background: rgb(4,2,3);
    background: -moz-linear-gradient(90deg, rgba(4,2,3,1) 0%, rgba(255,255,255,1) 50%, rgba(4,2,3,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(4,2,3,1) 0%, rgba(255,255,255,1) 50%, rgba(4,2,3,1) 100%);
    background: linear-gradient(90deg, rgba(4,2,3,1) 0%, rgba(255,255,255,1) 50%, rgba(4,2,3,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#040203",endColorstr="#040203",GradientType=1);
}

.bg-alcorcon{
    background: rgb(35,43,118);
    background: -moz-linear-gradient(90deg, rgba(35,43,118,1) 0%, rgba(255,255,255,1) 50%, rgba(252,201,47,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(35,43,118,1) 0%, rgba(255,255,255,1) 50%, rgba(252,201,47,1) 100%);
    background: linear-gradient(90deg, rgba(35,43,118,1) 0%, rgba(255,255,255,1) 50%, rgba(252,201,47,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#232b76",endColorstr="#fcc92f",GradientType=1);
}

.bg-almeria{
    background: rgb(236,21,37);
    background: -moz-linear-gradient(90deg, rgba(236,21,37,1) 0%, rgba(255,255,255,1) 50%, rgba(236,21,37,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(236,21,37,1) 0%, rgba(255,255,255,1) 50%, rgba(236,21,37,1) 100%);
    background: linear-gradient(90deg, rgba(236,21,37,1) 0%, rgba(255,255,255,1) 50%, rgba(236,21,37,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ec1525",endColorstr="#ec1525",GradientType=1);
}

.bg-arsenal{
    background: rgb(225,27,34);
background: -moz-linear-gradient(90deg, rgba(225,27,34,1) 0%, rgba(255,255,255,1) 50%, rgba(38,53,114,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(225,27,34,1) 0%, rgba(255,255,255,1) 50%, rgba(38,53,114,1) 100%);
background: linear-gradient(90deg, rgba(225,27,34,1) 0%, rgba(255,255,255,1) 50%, rgba(38,53,114,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e11b22",endColorstr="#263572",GradientType=1);
}

.bg-aston-villa{
    background: rgb(173,193,228);
background: -moz-linear-gradient(90deg, rgba(173,193,228,1) 0%, rgba(255,255,255,1) 50%, rgba(246,227,58,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(173,193,228,1) 0%, rgba(255,255,255,1) 50%, rgba(246,227,58,1) 100%);
background: linear-gradient(90deg, rgba(173,193,228,1) 0%, rgba(255,255,255,1) 50%, rgba(246,227,58,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#adc1e4",endColorstr="#f6e33a",GradientType=1);
}

.bg-atletico-madrid{
    background: rgb(255,4,4);
    background: -moz-linear-gradient(90deg, rgba(255,4,4,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(255,4,4,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    background: linear-gradient(90deg, rgba(255,4,4,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0404",endColorstr="#ffffff",GradientType=1);
}

.bg-barcelona{
    background: rgb(10,84,155);
background: -moz-linear-gradient(90deg, rgba(10,84,155,1) 0%, rgba(255,255,255,1) 50%, rgba(162,36,80,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(10,84,155,1) 0%, rgba(255,255,255,1) 50%, rgba(162,36,80,1) 100%);
background: linear-gradient(90deg, rgba(10,84,155,1) 0%, rgba(255,255,255,1) 50%, rgba(162,36,80,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a549b",endColorstr="#a22450",GradientType=1);
}

.bg-betis{
    background: rgb(25,152,86);
background: -moz-linear-gradient(90deg, rgba(25,152,86,1) 0%, rgba(255,255,255,1) 50%, rgba(25,152,86,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(25,152,86,1) 0%, rgba(255,255,255,1) 50%, rgba(25,152,86,1) 100%);
background: linear-gradient(90deg, rgba(25,152,86,1) 0%, rgba(255,255,255,1) 50%, rgba(25,152,86,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#199856",endColorstr="#199856",GradientType=1);
}

.bg-bilbao{
    background: rgb(255,4,4);
background: -moz-linear-gradient(90deg, rgba(255,4,4,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(255,4,4,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
background: linear-gradient(90deg, rgba(255,4,4,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0404",endColorstr="#ffffff",GradientType=1);
}

.bg-boavista{
    background: rgb(35,25,16);
background: -moz-linear-gradient(90deg, rgba(35,25,16,1) 0%, rgba(255,255,255,1) 50%, rgba(35,25,16,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(35,25,16,1) 0%, rgba(255,255,255,1) 50%, rgba(35,25,16,1) 100%);
background: linear-gradient(90deg, rgba(35,25,16,1) 0%, rgba(255,255,255,1) 50%, rgba(35,25,16,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#231910",endColorstr="#231910",GradientType=1);
}

.bg-borussia-d{
    background: rgb(248,229,51);
background: -moz-linear-gradient(90deg, rgba(248,229,51,1) 0%, rgba(255,255,255,1) 50%, rgba(35,25,16,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(248,229,51,1) 0%, rgba(255,255,255,1) 50%, rgba(35,25,16,1) 100%);
background: linear-gradient(90deg, rgba(248,229,51,1) 0%, rgba(255,255,255,1) 50%, rgba(35,25,16,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8e533",endColorstr="#231910",GradientType=1);
}

.bg-borussia-m{
    background: rgb(29,29,27);
background: -moz-linear-gradient(90deg, rgba(29,29,27,1) 0%, rgba(255,255,255,1) 50%, rgba(29,29,27,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(29,29,27,1) 0%, rgba(255,255,255,1) 50%, rgba(29,29,27,1) 100%);
background: linear-gradient(90deg, rgba(29,29,27,1) 0%, rgba(255,255,255,1) 50%, rgba(29,29,27,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d1d1b",endColorstr="#1d1d1b",GradientType=1);
}

.bg-cadiz{
    background: rgb(235,224,84);
background: -moz-linear-gradient(90deg, rgba(235,224,84,1) 0%, rgba(255,255,255,1) 50%, rgba(9,72,137,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(235,224,84,1) 0%, rgba(255,255,255,1) 50%, rgba(9,72,137,1) 100%);
background: linear-gradient(90deg, rgba(235,224,84,1) 0%, rgba(255,255,255,1) 50%, rgba(9,72,137,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ebe054",endColorstr="#094889",GradientType=1);
}

.bg-cartagena{
    background: rgb(148,48,69);
background: -moz-linear-gradient(90deg, rgba(148,48,69,1) 0%, rgba(255,255,255,1) 50%, rgba(34,34,34,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(148,48,69,1) 0%, rgba(255,255,255,1) 50%, rgba(34,34,34,1) 100%);
background: linear-gradient(90deg, rgba(148,48,69,1) 0%, rgba(255,255,255,1) 50%, rgba(34,34,34,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#943045",endColorstr="#222222",GradientType=1);
}

.bg-castellon{
    background: rgb(249,15,55);
background: -moz-linear-gradient(90deg, rgba(249,15,55,1) 0%, rgba(255,255,255,1) 50%, rgba(253,213,66,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(249,15,55,1) 0%, rgba(255,255,255,1) 50%, rgba(253,213,66,1) 100%);
background: linear-gradient(90deg, rgba(249,15,55,1) 0%, rgba(255,255,255,1) 50%, rgba(253,213,66,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f90f37",endColorstr="#fdd542",GradientType=1);
}

.bg-celta{
    background: rgb(140,195,234);
background: -moz-linear-gradient(90deg, rgba(140,195,234,1) 0%, rgba(255,255,255,1) 50%, rgba(140,195,234,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(140,195,234,1) 0%, rgba(255,255,255,1) 50%, rgba(140,195,234,1) 100%);
background: linear-gradient(90deg, rgba(140,195,234,1) 0%, rgba(255,255,255,1) 50%, rgba(140,195,234,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8cc3ea",endColorstr="#8cc3ea",GradientType=1);
}

.bg-chelsea{
    background: rgb(36,68,137);
background: -moz-linear-gradient(90deg, rgba(36,68,137,1) 0%, rgba(255,255,255,1) 50%, rgba(36,68,137,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(36,68,137,1) 0%, rgba(255,255,255,1) 50%, rgba(36,68,137,1) 100%);
background: linear-gradient(90deg, rgba(36,68,137,1) 0%, rgba(255,255,255,1) 50%, rgba(36,68,137,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#244489",endColorstr="#244489",GradientType=1);
}

.bg-deportivo{
    background: rgb(78,142,204);
background: -moz-linear-gradient(90deg, rgba(78,142,204,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(78,142,204,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
background: linear-gradient(90deg, rgba(78,142,204,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4e8ecc",endColorstr="#ffffff",GradientType=1);
}

.bg-eibar{
    background: rgb(70,114,179);
background: -moz-linear-gradient(90deg, rgba(70,114,179,1) 0%, rgba(255,255,255,1) 50%, rgba(70,114,179,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(70,114,179,1) 0%, rgba(255,255,255,1) 50%, rgba(70,114,179,1) 100%);
background: linear-gradient(90deg, rgba(70,114,179,1) 0%, rgba(255,255,255,1) 50%, rgba(70,114,179,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4672b3",endColorstr="#4672b3",GradientType=1);
}

.bg-elche{
    background: rgb(6,102,55);
    background: -moz-linear-gradient(90deg, rgba(6,102,55,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(6,102,55,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    background: linear-gradient(90deg, rgba(6,102,55,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#066637",endColorstr="#ffffff",GradientType=1);
}

.bg-espanyol{
    background: rgb(46,120,187);
background: -moz-linear-gradient(90deg, rgba(46,120,187,1) 0%, rgba(255,255,255,1) 50%, rgba(46,120,187,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(46,120,187,1) 0%, rgba(255,255,255,1) 50%, rgba(46,120,187,1) 100%);
background: linear-gradient(90deg, rgba(46,120,187,1) 0%, rgba(255,255,255,1) 50%, rgba(46,120,187,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2e78bb",endColorstr="#2e78bb",GradientType=1);
}

.bg-fuenlabrada{
    background: rgb(1,79,166);
background: -moz-linear-gradient(90deg, rgba(1,79,166,1) 0%, rgba(255,255,255,1) 50%, rgba(1,79,166,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(1,79,166,1) 0%, rgba(255,255,255,1) 50%, rgba(1,79,166,1) 100%);
background: linear-gradient(90deg, rgba(1,79,166,1) 0%, rgba(255,255,255,1) 50%, rgba(1,79,166,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#014fa6",endColorstr="#014fa6",GradientType=1);
}

.bg-getafe{
    background: rgb(12,89,147);
background: -moz-linear-gradient(90deg, rgba(12,89,147,1) 0%, rgba(255,255,255,1) 50%, rgba(12,89,147,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(12,89,147,1) 0%, rgba(255,255,255,1) 50%, rgba(12,89,147,1) 100%);
background: linear-gradient(90deg, rgba(12,89,147,1) 0%, rgba(255,255,255,1) 50%, rgba(12,89,147,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0c5993",endColorstr="#0c5993",GradientType=1);
}

.bg-girona{
    background: rgb(200,16,48);
background: -moz-linear-gradient(90deg, rgba(200,16,48,1) 0%, rgba(255,255,255,1) 50%, rgba(253,215,54,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(200,16,48,1) 0%, rgba(255,255,255,1) 50%, rgba(253,215,54,1) 100%);
background: linear-gradient(90deg, rgba(200,16,48,1) 0%, rgba(255,255,255,1) 50%, rgba(253,215,54,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c81030",endColorstr="#fdd736",GradientType=1);
}

.bg-granada{
    background: rgb(162,28,47);
background: -moz-linear-gradient(90deg, rgba(162,28,47,1) 0%, rgba(255,255,255,1) 50%, rgba(162,28,47,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(162,28,47,1) 0%, rgba(255,255,255,1) 50%, rgba(162,28,47,1) 100%);
background: linear-gradient(90deg, rgba(162,28,47,1) 0%, rgba(255,255,255,1) 50%, rgba(162,28,47,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a21c2f",endColorstr="#a21c2f",GradientType=1);
}

.bg-huesca{
    background: rgb(162,28,47);
background: -moz-linear-gradient(90deg, rgba(162,28,47,1) 0%, rgba(255,255,255,1) 50%, rgba(29,42,118,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(162,28,47,1) 0%, rgba(255,255,255,1) 50%, rgba(29,42,118,1) 100%);
background: linear-gradient(90deg, rgba(162,28,47,1) 0%, rgba(255,255,255,1) 50%, rgba(29,42,118,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a21c2f",endColorstr="#1d2a76",GradientType=1);
}

.bg-deportivo{
    background: rgb(78,142,204);
    background: -moz-linear-gradient(90deg, rgba(78,142,204,1) 0%, rgba(255,255,255,1) 50%, rgba(78,142,204,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(78,142,204,1) 0%, rgba(255,255,255,1) 50%, rgba(78,142,204,1) 100%);
    background: linear-gradient(90deg, rgba(78,142,204,1) 0%, rgba(255,255,255,1) 50%, rgba(78,142,204,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4e8ecc",endColorstr="#4e8ecc",GradientType=1);
}

.bg-laspalmas{
    background: rgb(219,219,8);
background: -moz-linear-gradient(90deg, rgba(219,219,8,1) 0%, rgba(255,255,255,1) 50%, rgba(6,69,157,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(219,219,8,1) 0%, rgba(255,255,255,1) 50%, rgba(6,69,157,1) 100%);
background: linear-gradient(90deg, rgba(219,219,8,1) 0%, rgba(255,255,255,1) 50%, rgba(6,69,157,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dbdb08",endColorstr="#06459d",GradientType=1);
}

.bg-leganes{
    background: rgb(8,76,144);
background: -moz-linear-gradient(90deg, rgba(8,76,144,1) 0%, rgba(255,255,255,1) 50%, rgba(8,76,144,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(8,76,144,1) 0%, rgba(255,255,255,1) 50%, rgba(8,76,144,1) 100%);
background: linear-gradient(90deg, rgba(8,76,144,1) 0%, rgba(255,255,255,1) 50%, rgba(8,76,144,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#084c90",endColorstr="#084c90",GradientType=1);
}

.bg-levante{
    background: rgb(31,93,166);
background: -moz-linear-gradient(90deg, rgba(31,93,166,1) 0%, rgba(255,255,255,1) 50%, rgba(173,20,61,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(31,93,166,1) 0%, rgba(255,255,255,1) 50%, rgba(173,20,61,1) 100%);
background: linear-gradient(90deg, rgba(31,93,166,1) 0%, rgba(255,255,255,1) 50%, rgba(173,20,61,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1f5da6",endColorstr="#ad143d",GradientType=1);
}

.bg-liverpool{
    background: rgb(205,16,30);
background: -moz-linear-gradient(90deg, rgba(205,16,30,1) 0%, rgba(255,255,255,1) 50%, rgba(205,16,30,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(205,16,30,1) 0%, rgba(255,255,255,1) 50%, rgba(205,16,30,1) 100%);
background: linear-gradient(90deg, rgba(205,16,30,1) 0%, rgba(255,255,255,1) 50%, rgba(205,16,30,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cd101e",endColorstr="#cd101e",GradientType=1);
}

.bg-logrones{
    background: rgb(189,15,27);
background: -moz-linear-gradient(90deg, rgba(189,15,27,1) 0%, rgba(255,255,255,1) 50%, rgba(189,15,27,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(189,15,27,1) 0%, rgba(255,255,255,1) 50%, rgba(189,15,27,1) 100%);
background: linear-gradient(90deg, rgba(189,15,27,1) 0%, rgba(255,255,255,1) 50%, rgba(189,15,27,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bd0f1b",endColorstr="#bd0f1b",GradientType=1);
}

.bg-lugo{
    background: rgb(225,23,33);
background: -moz-linear-gradient(90deg, rgba(225,23,33,1) 0%, rgba(255,255,255,1) 50%, rgba(225,23,33,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(225,23,33,1) 0%, rgba(255,255,255,1) 50%, rgba(225,23,33,1) 100%);
background: linear-gradient(90deg, rgba(225,23,33,1) 0%, rgba(255,255,255,1) 50%, rgba(225,23,33,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e11721",endColorstr="#e11721",GradientType=1);
}

.bg-malaga{
    background: rgb(10,75,151);
background: -moz-linear-gradient(90deg, rgba(10,75,151,1) 0%, rgba(255,255,255,1) 50%, rgba(10,75,151,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(10,75,151,1) 0%, rgba(255,255,255,1) 50%, rgba(10,75,151,1) 100%);
background: linear-gradient(90deg, rgba(10,75,151,1) 0%, rgba(255,255,255,1) 50%, rgba(10,75,151,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a4b97",endColorstr="#0a4b97",GradientType=1);
}

.bg-mallorca{
    background: rgb(251,189,55);
background: -moz-linear-gradient(90deg, rgba(251,189,55,1) 0%, rgba(255,255,255,1) 50%, rgba(236,31,44,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(251,189,55,1) 0%, rgba(255,255,255,1) 50%, rgba(236,31,44,1) 100%);
background: linear-gradient(90deg, rgba(251,189,55,1) 0%, rgba(255,255,255,1) 50%, rgba(236,31,44,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbbd37",endColorstr="#ec1f2c",GradientType=1);
}

.bg-maritimo{
    background: rgb(49,121,75);
background: -moz-linear-gradient(90deg, rgba(49,121,75,1) 0%, rgba(255,255,255,1) 50%, rgba(49,121,75,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(49,121,75,1) 0%, rgba(255,255,255,1) 50%, rgba(49,121,75,1) 100%);
background: linear-gradient(90deg, rgba(49,121,75,1) 0%, rgba(255,255,255,1) 50%, rgba(49,121,75,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#31794b",endColorstr="#31794b",GradientType=1);
}

.bg-mirandes{
    background: rgb(215,10,31);
background: -moz-linear-gradient(90deg, rgba(215,10,31,1) 0%, rgba(255,255,255,1) 50%, rgba(17,17,17,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(215,10,31,1) 0%, rgba(255,255,255,1) 50%, rgba(17,17,17,1) 100%);
background: linear-gradient(90deg, rgba(215,10,31,1) 0%, rgba(255,255,255,1) 50%, rgba(17,17,17,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70a1f",endColorstr="#111111",GradientType=1);
}

.bg-newcastle{
    background: rgb(17,17,17);
background: -moz-linear-gradient(90deg, rgba(17,17,17,1) 0%, rgba(255,255,255,1) 50%, rgba(17,17,17,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(17,17,17,1) 0%, rgba(255,255,255,1) 50%, rgba(17,17,17,1) 100%);
background: linear-gradient(90deg, rgba(17,17,17,1) 0%, rgba(255,255,255,1) 50%, rgba(17,17,17,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#111111",endColorstr="#111111",GradientType=1);
}

.bg-olympiacos{
    background: rgb(225,15,33);
background: -moz-linear-gradient(90deg, rgba(225,15,33,1) 0%, rgba(255,255,255,1) 50%, rgba(225,15,33,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(225,15,33,1) 0%, rgba(255,255,255,1) 50%, rgba(225,15,33,1) 100%);
background: linear-gradient(90deg, rgba(225,15,33,1) 0%, rgba(255,255,255,1) 50%, rgba(225,15,33,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e10f21",endColorstr="#e10f21",GradientType=1);
}

.bg-oviedo{
    background: rgb(16,68,171);
background: -moz-linear-gradient(90deg, rgba(16,68,171,1) 0%, rgba(255,255,255,1) 50%, rgba(16,68,171,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(16,68,171,1) 0%, rgba(255,255,255,1) 50%, rgba(16,68,171,1) 100%);
background: linear-gradient(90deg, rgba(16,68,171,1) 0%, rgba(255,255,255,1) 50%, rgba(16,68,171,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1044ab",endColorstr="#1044ab",GradientType=1);
}

.bg-paris-sg{
    background: rgb(6,66,111);
background: -moz-linear-gradient(90deg, rgba(6,66,111,1) 0%, rgba(255,255,255,1) 50%, rgba(6,66,111,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(6,66,111,1) 0%, rgba(255,255,255,1) 50%, rgba(6,66,111,1) 100%);
background: linear-gradient(90deg, rgba(6,66,111,1) 0%, rgba(255,255,255,1) 50%, rgba(6,66,111,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#06426f",endColorstr="#06426f",GradientType=1);
}

.bg-ponferradina{
    background: rgb(20,129,175);
background: -moz-linear-gradient(90deg, rgba(20,129,175,1) 0%, rgba(255,255,255,1) 50%, rgba(20,129,175,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(20,129,175,1) 0%, rgba(255,255,255,1) 50%, rgba(20,129,175,1) 100%);
background: linear-gradient(90deg, rgba(20,129,175,1) 0%, rgba(255,255,255,1) 50%, rgba(20,129,175,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1481af",endColorstr="#1481af",GradientType=1);
}

.bg-porto{
    background: rgb(27,115,181);
background: -moz-linear-gradient(90deg, rgba(27,115,181,1) 0%, rgba(255,255,255,1) 50%, rgba(27,115,181,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(27,115,181,1) 0%, rgba(255,255,255,1) 50%, rgba(27,115,181,1) 100%);
background: linear-gradient(90deg, rgba(27,115,181,1) 0%, rgba(255,255,255,1) 50%, rgba(27,115,181,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b73b5",endColorstr="#1b73b5",GradientType=1);
}

.bg-rayo{
    background: rgb(223,39,49);
background: -moz-linear-gradient(90deg, rgba(223,39,49,1) 0%, rgba(255,255,255,1) 50%, rgba(223,39,49,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(223,39,49,1) 0%, rgba(255,255,255,1) 50%, rgba(223,39,49,1) 100%);
background: linear-gradient(90deg, rgba(223,39,49,1) 0%, rgba(255,255,255,1) 50%, rgba(223,39,49,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#df2731",endColorstr="#df2731",GradientType=1);
}

.bg-real-madrid{
    background: rgb(46,48,148);
background: -moz-linear-gradient(90deg, rgba(46,48,148,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(46,48,148,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
background: linear-gradient(90deg, rgba(46,48,148,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2e3094",endColorstr="#ffffff",GradientType=1);
}

.bg-real-sociedad{
    background: rgb(1,131,204);
background: -moz-linear-gradient(90deg, rgba(1,131,204,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(1,131,204,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
background: linear-gradient(90deg, rgba(1,131,204,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0183cc",endColorstr="#ffffff",GradientType=1);
}

.bg-roma{
    background: rgb(234,183,62);
background: -moz-linear-gradient(90deg, rgba(234,183,62,1) 0%, rgba(255,255,255,1) 50%, rgba(128,24,48,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(234,183,62,1) 0%, rgba(255,255,255,1) 50%, rgba(128,24,48,1) 100%);
background: linear-gradient(90deg, rgba(234,183,62,1) 0%, rgba(255,255,255,1) 50%, rgba(128,24,48,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eab73e",endColorstr="#801830",GradientType=1);
}

.bg-sabadell{
    background: rgb(244,27,29);
background: -moz-linear-gradient(90deg, rgba(244,27,29,1) 0%, rgba(255,255,255,1) 50%, rgba(249,227,50,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(244,27,29,1) 0%, rgba(255,255,255,1) 50%, rgba(249,227,50,1) 100%);
background: linear-gradient(90deg, rgba(244,27,29,1) 0%, rgba(255,255,255,1) 50%, rgba(249,227,50,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f41b1d",endColorstr="#f9e332",GradientType=1);
}

.bg-schalke04{
    background: rgb(35,87,157);
background: -moz-linear-gradient(90deg, rgba(35,87,157,1) 0%, rgba(255,255,255,1) 50%, rgba(35,87,157,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(35,87,157,1) 0%, rgba(255,255,255,1) 50%, rgba(35,87,157,1) 100%);
background: linear-gradient(90deg, rgba(35,87,157,1) 0%, rgba(255,255,255,1) 50%, rgba(35,87,157,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#23579d",endColorstr="#23579d",GradientType=1);
}

.bg-sevilla{
    background: rgb(223,17,36);
background: -moz-linear-gradient(90deg, rgba(223,17,36,1) 0%, rgba(255,255,255,1) 50%, rgba(223,17,36,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(223,17,36,1) 0%, rgba(255,255,255,1) 50%, rgba(223,17,36,1) 100%);
background: linear-gradient(90deg, rgba(223,17,36,1) 0%, rgba(255,255,255,1) 50%, rgba(223,17,36,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#df1124",endColorstr="#df1124",GradientType=1);
}

.bg-sporting{
    background: rgb(236,31,42);
background: -moz-linear-gradient(90deg, rgba(236,31,42,1) 0%, rgba(255,255,255,1) 50%, rgba(236,31,42,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(236,31,42,1) 0%, rgba(255,255,255,1) 50%, rgba(236,31,42,1) 100%);
background: linear-gradient(90deg, rgba(236,31,42,1) 0%, rgba(255,255,255,1) 50%, rgba(236,31,42,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ec1f2a",endColorstr="#ec1f2a",GradientType=1);
}

.bg-tenerife{
    background: rgb(42,61,144);
background: -moz-linear-gradient(90deg, rgba(42,61,144,1) 0%, rgba(255,255,255,1) 50%, rgba(42,61,144,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(42,61,144,1) 0%, rgba(255,255,255,1) 50%, rgba(42,61,144,1) 100%);
background: linear-gradient(90deg, rgba(42,61,144,1) 0%, rgba(255,255,255,1) 50%, rgba(42,61,144,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2a3d90",endColorstr="#2a3d90",GradientType=1);
}

.bg-valencia{
    background: rgb(252,221,50);
background: -moz-linear-gradient(90deg, rgba(252,221,50,1) 0%, rgba(255,255,255,1) 50%, rgba(223,40,39,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(252,221,50,1) 0%, rgba(255,255,255,1) 50%, rgba(223,40,39,1) 100%);
background: linear-gradient(90deg, rgba(252,221,50,1) 0%, rgba(255,255,255,1) 50%, rgba(223,40,39,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcdd32",endColorstr="#df2827",GradientType=1);
}

.bg-valladolid{
    background: rgb(143,110,177);
background: -moz-linear-gradient(90deg, rgba(143,110,177,1) 0%, rgba(255,255,255,1) 50%, rgba(143,110,177,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(143,110,177,1) 0%, rgba(255,255,255,1) 50%, rgba(143,110,177,1) 100%);
background: linear-gradient(90deg, rgba(143,110,177,1) 0%, rgba(255,255,255,1) 50%, rgba(143,110,177,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8f6eb1",endColorstr="#8f6eb1",GradientType=1);
}

.bg-villareal{
    background: rgb(248,223,93);
background: -moz-linear-gradient(90deg, rgba(248,223,93,1) 0%, rgba(255,255,255,1) 50%, rgba(248,223,93,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(248,223,93,1) 0%, rgba(255,255,255,1) 50%, rgba(248,223,93,1) 100%);
background: linear-gradient(90deg, rgba(248,223,93,1) 0%, rgba(255,255,255,1) 50%, rgba(248,223,93,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8df5d",endColorstr="#f8df5d",GradientType=1);
}

.bg-zaragoza{
    background: rgb(43,103,204);
background: -moz-linear-gradient(90deg, rgba(43,103,204,1) 0%, rgba(255,255,255,1) 50%, rgba(43,103,204,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(43,103,204,1) 0%, rgba(255,255,255,1) 50%, rgba(43,103,204,1) 100%);
background: linear-gradient(90deg, rgba(43,103,204,1) 0%, rgba(255,255,255,1) 50%, rgba(43,103,204,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2b67cc",endColorstr="#2b67cc",GradientType=1);
}