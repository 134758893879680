

.box{
    padding: 0;
    box-shadow: none;
    background-color: transparent !important;
    // @include box-shadow;
    header.header-box{
        padding:1rem 1rem .7rem 1rem;
        @include border-radius;
        background-color: $navy-light2;
        text-align: center;
        .equipo-ficha{
            text-align: left;
        }
        h2{
            color: $color-white;
        }
        &.is-toggle{
            background-color: $navy-dark;
            padding: 0;
        }
    }
    .body-box{
        margin-top: 2px;
        @include border-radius;
        padding: 1rem;
        background-color: $navy-medium;
        color: $color-white;
        @include tablet{
            padding: 2rem;
        }
        &.bg-body1{
            background-image: url(#{$static-content}/images/bg/bg5.jpg);  
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        &.bg-body2{
            background-image: url(#{$static-content}/images/bg/bg6.jpg);  
            background-repeat: no-repeat;
            background-position: top right;
            background-size: cover;
        }
    }
    // &.box-white{
    //     header.header-box, .body-box{
    //         background-color: $color-white;
    //         h2{
    //             color: $dark;
    //         }
    //     }
    // }
    &.box-white{
        .body-box{
            background-color: $color-white;
        }
    }
}

//bg & modules

.bg-success{
    background-color: $success;
}

.bg-danger{
    background-color: $danger;
    color: $color-white;
}

// shapes

.ticket{
    @include border-radius;
    text-align: center;
    margin: 2px;
    padding: 1rem;
    &.is-success{
        border: 1px solid $success;
        // background-color: rgba($success, .1);
        .qty{
            color: $success;
        }
    }
    &.is-danger{
        border: 1px solid $danger;
        // background-color: rgba($danger, .1);
        .qty{
            color: $danger;
        }
    }
}

.box-item{
    border: 1px solid $gray1;
    padding: 1rem;
    border-radius: 3px;
    background-color: $color-white;
}
