/* Bulma Utilities */
@-webkit-keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.popover {
  position: relative;
  display: inline-block;
}

.popover .popover-content {
  z-index: 99999;
  position: absolute;
  display: inline-block;
  opacity: 0;
  font-size: 16px !important;
  visibility: hidden;
  padding: .4rem .8rem;
  color: #333333;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 24rem;
}

.popover .popover-content::before {
  position: absolute;
  content: '';
  border-style: solid;
  pointer-events: none;
  height: 0;
  width: 0;
  top: 100%;
  left: 50%;
  border-color: transparent;
  border-bottom-color: white;
  border-left-color: white;
  border-width: 0.4rem;
  margin-left: -0.4rem;
  margin-top: -0.4rem;
  transform-origin: center;
  box-shadow: -1px 1px 2px rgba(10, 10, 10, 0.2);
}

.popover .popover-content {
  top: auto !important;
  bottom: 100% !important;
  left: 50% !important;
  right: auto !important;
  transform: translate(-50%, -0.7rem) !important;
}

.popover .popover-content::before {
  top: 100% !important;
  bottom: auto !important;
  left: 50% !important;
  right: auto !important;
  transform: rotate(-45deg);
}

.popover.is-popover-right .popover-content {
  top: auto !important;
  bottom: 50% !important;
  left: 100% !important;
  right: auto !important;
  transform: translate(0.7rem, 50%) !important;
}

.popover.is-popover-right .popover-content::before {
  top: 50% !important;
  bottom: auto !important;
  left: 0 !important;
  right: auto !important;
  transform: rotate(45deg);
}

.popover.is-popover-bottom .popover-content {
  top: 100% !important;
  bottom: auto !important;
  left: 50% !important;
  right: auto !important;
  transform: translate(-50%, 0.7rem) !important;
}

.popover.is-popover-bottom .popover-content::before {
  top: 0 !important;
  bottom: auto !important;
  left: 50% !important;
  right: auto !important;
  transform: rotate(135deg);
}

.popover.is-popover-left .popover-content {
  top: auto !important;
  bottom: 50% !important;
  left: auto !important;
  right: 100% !important;
  transform: translate(-0.7rem, 50%) !important;
}

.popover.is-popover-left .popover-content::before {
  top: 50% !important;
  bottom: auto !important;
  left: 100% !important;
  right: auto !important;
  transform: rotate(-135deg);
}

.popover.is-popover-active .popover-content, .popover:hover .popover-content {
  opacity: 1;
  visibility: visible;
}

.popover .popover-trigger:focus ~ .popover-content {
  opacity: 1;
  visibility: visible;
}

.popover-ticket{
  a{
    .material-icons{
      color: $danger;
      font-size: 24px;
      margin: .8rem auto;
    }
  }
 
}